import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import {  faGithub,  faTwitter,  faGoogle,  faMastodon,faLinkedin,  faFacebook,  faInstagram,} from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";


const Footer = ({ theme }) => {
  return (
    <div
      style={{ position: "relative", marginTop: "2em", zIndex: 999, bottom: 0 }}
    >
      <div
        className="py-md-3"
        style={{  background: theme.body === "#ffffff" ? "#8B1E3F" : theme.body, color: theme.text,  position: "absolute", bottom: 0,  width: "100%"  }} >
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-md-4">
              <ul className="list-unstyled">
                <li>
                  <Link to="/about" className="text-light ">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="text-light ">
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="/pp" className="text-light ">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/help" className="text-light ">
                    Help
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-4">
              <ul className="list-unstyled">
                <li>
                  <Link
                    to="https://github.com/triyotech"
                    className="text-light"
                  >
                    <FontAwesomeIcon icon={faGithub} />
                  </Link>
                </li>
                <li>
                  <Link
                     to="https://maps.app.goo.gl/L5qhi9Ym3F5T4ghA7"
                    className="text-light"
                  >
                    <FontAwesomeIcon icon={faGoogle} />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://in.linkedin.com/company/triyotechindia"
                    className="text-light"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.facebook.com/triyotech"
                    className="text-light"
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.instagram.com/triyotechindian/"
                    className="text-light"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </Link>
                </li>
                <li>
                  <Link to="https://twitter.com/triyotech" className="text-light" >
                    <FontAwesomeIcon icon={faTwitter} />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://mastodon.social/@triyotech"
                    className="text-light"
                  >
                    <FontAwesomeIcon icon={faMastodon} />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="d-md-none col-2" />
            <div className="col-5 col-md-4 my-md-0 my-2 d-flex align-items-center justify-content-end">
              <Link
                className="rounded bg-white text-dark text-decoration-none p-2 d-flex justify-content-between align-content-center align-items-center"
                to="tel:+918319697083"
              >
                <FontAwesomeIcon color="green" icon={faPhone} /> <span className="d-none d-md-block px-2"> Click to Call
                Now </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
