import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faToggleOff,
  faToggleOn,
  // faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

function Header({ theme, toggleTheme, toggleMenubar, menubarVisible }) {
  const [logo , setLogo] = useState('')
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://triyotechbackend.onrender.com/logo', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (response.ok) {
          const data = await response.text()
          console.log("data", data)
         setLogo(data)
        } else {
          throw new Error('Network response was not ok.');
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();

  }, []);

  return (
    <header
      className="containerH"
      style={{ backgroundColor: theme.body, color: theme.text }}
    >
      <div className=" m-0" style={{}}>
        <h1 className="pe-md-5 logo-font text-brand btn border-0 p-0 m-0"
          style={{
            fontFamily: "Arial, sans-serif",
            fontWeight: "bold",
            // fontSize: window.innerWidth <=520 ? "1rem" : "2rem",
            fontStyle: "italic",
           minWidth:120,
          
          }}
        >
      {logo === '' ? "Triyotech " : logo}

        </h1>
        <div className="d-md-none d-block pe-3"></div>
        <ul
          className={menubarVisible ? "menubar-visible " : ""}
          style={
            menubarVisible ? { backgroundColor: theme.text , color:theme.text } : {}
          }
        >
          <li>
            <Link  style={ menubarVisible ? {  color:theme.body } :{ color: theme.textsecondary }} to="/">
              Home
            </Link>
          </li>
          <li>
            <Link  style={ menubarVisible ? {  color:theme.body } :{ color: theme.textsecondary }} to="/events">
              Events
            </Link>
          </li>
          <li>
            <Link  style={ menubarVisible ? {  color:theme.body } :{ color: theme.textsecondary }} to="/services">
              Services
            </Link>
          </li>
       <li>
            <Link  style={ menubarVisible ? {  color:theme.body } :{ color: theme.textsecondary }} to="/pricing">
              Pricing
            </Link>
          </li>
           {/*   <li>
            <Link style={ menubarVisible ? {  color:theme.body } :{ color: theme.textsecondary }} to="/help">
              Help
            </Link>
          </li>
          <li>
            <Link style={ menubarVisible ? {  color:theme.body } :{ color: theme.textsecondary }} to="/about">
              About Us
            </Link>
          </li>
          <li>
            <Link style={ menubarVisible ? {  color:theme.body } :{ color: theme.textsecondary }} to="/appointment">
              Appointment
            </Link>
          </li> */}
          <li>
            <Link  style={ menubarVisible ? {  color:theme.body } :{ color: theme.textsecondary }} to="/contact">
              Contact us
            </Link>
          </li>
          {/* <li className="d-block d-md-none d-lg-none mt-4">
            <Link
              className="active p-2 mt-2 rounded-pill"
              style={{ color: theme.body!=="#ffffff"  && theme.textsecondary }}
              to="/login"
            >
              <FontAwesomeIcon icon={faUser} />
            </Link>
          </li> */}
        </ul>
        {/* <ul className="auth px-md-5" >
          <li>
            <Link style={{ color: theme.textsecondary }} to="/signup">
              Signup
            </Link>
          </li>
          <li>
            <Link
              className="active"
              style={{ color: theme.body!=="#ffffff"  && theme.textsecondary }}
              to="/login"
            >
              Login
            </Link>
          </li>
        </ul> */}
    <button className="toggle" onClick={toggleTheme} style={window.innerWidth >= 520 ? { position: "absolute", right: 20 } : null}>  {/* TODO this style will remove next version */}
    <FontAwesomeIcon
        style={{ color: theme.body !== "#ffffff" ? "white" : "black" }}
        className="fa-2x toggle-icon"
        icon={theme.body === "#ffffff" ? faToggleOff : faToggleOn} 
    />
</button>

        <button className="toggle px-5 px-md-0" onClick={toggleMenubar}>
          <FontAwesomeIcon
           style={{ color: theme.body!=="#ffffff"  ? "white" : "black" }}
            className="fa-2x toggle-icon"
            icon={!menubarVisible ? faBars : faXmark}
          />
        </button>
      </div>
    </header>
  );
}

export default Header;
