import React from "react";
import { Link, useNavigate } from "react-router-dom";

function PageNotFound({ theme }) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1)
    console.log("clicked")
  };

  return (
    <div className="row col-md-12 align-items-center justify-content-center" style={{ backgroundColor: theme.body, color: theme.text, height: "130vh", cursor: "crosshair" }}>
      <div className="justify-content-center align-items-center col-md-6 d-flex flex-column">
        <img src={require("../img/Scarecrow.png")} alt="404-Scarecrow" height={500} />
      </div>
      <div className="row justify-content-center col-md-5 align-items-center flex-column">
        <h2 className="">I have bad news for you</h2>
        <p className="">
          The page you are looking for might be removed or is temporarily
          unavailable
        </p>
        <Link onClick={goBack} className="btn w-50 my-5 btn-primary">Back to previous screen</Link>
      </div>
    </div>
  );
}

export default PageNotFound;
