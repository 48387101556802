import Carousel from 'react-bootstrap/Carousel';
import thirdslide from '../img/thirdslide.png';

function Slide({ theme }) {
  const overlay = {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.body === "#ffffff" ? "#000000a6" : "#8b1e3fa6",
    animation: theme.body === "#ffffff" && 'revealOverlay 3s ease forwards',
    color: "#fff",
    width:"100%"
  };

  return (
    <Carousel data-bs-theme="dark">
      <Carousel.Item>
        <img height={300}
          className="d-block px-md-4"
          src={require("../img/slider (1).png")}
          alt="First slide"
        />
        <Carousel.Caption style={overlay} className={`rounded p-md-5`}>
          <h5 style={{ color: "#fff", position: "relative", zIndex: 9 }}>App Development</h5>
          <p style={{ color: "#fff", position: "relative", zIndex: 2 }}>At Triyotech India, we excel in crafting innovative and user-friendly mobile applications.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img height={300}
          className="d-block px-md-4"
          src={require("../img/digitalmarketing.png")}
          alt="Second slide"
        />
        <Carousel.Caption style={overlay} className={`rounded p-md-5`}>
          <h5 style={{ color: "#fff", position: "relative", zIndex: 9 }}>Website Development</h5>
          <p style={{ color: "#fff", position: "relative", zIndex: 2 }}>Triyotech India specializes in creating dynamic and engaging websites tailored to your business needs.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img height={300}
          className="d-block px-md-4"
          src={thirdslide}
          alt="Third slide"
        />
        <Carousel.Caption style={overlay} className={`rounded p-md-5`}>
          <h5 style={{ color: "#fff", position: "relative", zIndex: 9 }}>Digital Marketing</h5>
          <p style={{ color: "#fff", position: "relative", zIndex: 2 }}>Unlock the potential of your online presence with Triyotech India's comprehensive digital marketing strategies.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Slide;
