import React from 'react';
import { Link } from 'react-router-dom';

const Hero = ({theme, url, heading, paragraph }) => {
  const bgimg = {
    backgroundImage: `url(${url})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    maxWidth: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '40vh',
    padding: '6em 0',
    backgroundColor:theme.body ,
    position: 'relative',
    // borderRadious:50
  }; 

  const overlay = {
    content: '""',
    position: 'absolute',
    zIndex:1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor:theme.body!=="#ffffff"?"#000000a6":"#8b1e3fa6" ,
    animation:theme.body!=="#ffffff" && 'revealOverlay 3s ease forwards',
  };

  return (
    <div className='mt-md-5 pt-2 rounded-circle d-md-block d-flex justify-content-center align-content-center align-items-center flex-direction-column'>
      <div style={bgimg} className='row' >
        <p style={{ textAlign: 'center', color: '#fff', fontSize: '2rem',zIndex:99 }}>{heading}</p>
        <p style={{ textAlign: 'center', color: '#fff', fontSize: '1rem' ,zIndex:99}}>{paragraph}</p>
        <Link to="/contact" style={{ width: '10em', backgroundColor: '#8B1E3F', color: '#fff',zIndex:99 }} className='btn btn-sm'>read more</Link>
        <div style={overlay}></div>
      </div>
    </div>
  );
};

export default Hero;
