import React, { useState } from "react";
import img1 from "../img/recentprojects/1.png";
import img2 from "../img/recentprojects/2.png";
import img3 from "../img/recentprojects/3.png";
import img4 from "../img/recentprojects/4.png";
import img5 from "../img/recentprojects/5.png";
import img6 from "../img/recentprojects/6.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import "./recentwork.css";
const RecentWork = ({ theme }) => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const projects = [
    {
      img: img1,
      heading: "Kiden School Website",
      description: "School website with admin and reception dashboards.",
      category: "Web Development",
      link: "https://ordainit.com/kiden",
    },
    {
      img: img2,
      heading: "NCFM India",
      description: "Design and development of landing pages.",
      category: "Web Development",
      link: "https://www.ncfm-india.com/ORE/Think_like_a_CEO/",
    },
    {
      img: img3,
      heading: "Hospital Management",
      description:
        "Dashboards for admin, doctors, patients, and hospital website & app.",
      category: "Web Development",
      link: "https://medash.netlify.app/",
    },
    {
      img: img4,
      heading: "Manivel Pvt Ltd",
      description: "Providing CRM solutions for Manivel Pvt Ltd.",
      category: "Web Development",
      link: "https://manivel.com/",
    },
    {
      img: img5,
      heading: "Mansvi Mart",
      description: "Development of CRM and mobile app.",
      category: "App Development",
      link: "",
    },
    {
      img: img6,
      heading: "Manasvi Mart",
      description: "Development of an e-commerce platform.",
      category: "App Development",
      link: "https://manasvimart.in",
    },
    {
      img: "https://images.ui8.net/uploads/thumbnail-2_1628862346457.png",
      heading: "HomLoto",
      description: "Development real e-state platform.",
      category: "App Development",
      link: "https://play.google.com/store/apps/details?id=in.theupdesh.theupdesh&hl=en_IN&gl=US",
    },
  ];

  const filteredProjects =
    selectedCategory === "All"
      ? projects
      : projects.filter((project) => project.category === selectedCategory);

  return (
    <div
      id="recent"
      className="px-5 d-flex flex-column align-items-center justify-content-center py-5"
      style={{
        backgroundColor:
          theme.text === "#ffffff" ? theme.secondaryColor : "#ffff",
        color: theme.text,
      }}
    >
      <h2 className="text-uppercase font-weight-bold">
        Our <span className="text-brand">Recent</span> Work
      </h2>
      <div className="horizontal-scrolling-items">
        <p className="italic text-justify horizontal-scrolling-items__item"></p>
        <p className="italic text-justify horizontal-scrolling-items__item">
          We take pride in presenting our recent projects and achievements in
          the realm of technology and innovation.
        </p>
        <p className="italic text-justify horizontal-scrolling-items__item">
          With a dedicated team of experts and an unwavering commitment to
          excellence, we strive to deliver cutting-edge solutions that not only
          meet our clients' needs but also surpass their expectations. From web
          development to app development and beyond, we are driven by a passion
          to push the boundaries of what's possible and create impactful
          experiences that leave a lasting impression.{" "}
        </p>
      </div>

      <div className="d-flex justify-content-start mb-3">
        <div
          className={`btn text-brand mr-2 ${
            selectedCategory === "All" && "active"
          } ${selectedCategory === "All" && "bg-brand"}`}
          onClick={() => setSelectedCategory("All")}
        >
          All
        </div>
        <div
          className={`btn text-brand mr-2 ${
            selectedCategory === "Web Development" && "active"
          } ${selectedCategory === "Web Development" && "bg-brand"}`}
          onClick={() => setSelectedCategory("Web Development")}
        >
          Web Development
        </div>
        <div
          className={`btn text-brand ${
            selectedCategory === "App Development" && "active"
          } ${selectedCategory === "App Development" && "bg-brand"}`}
          onClick={() => setSelectedCategory("App Development")}
        >
          App Development
        </div>
      </div>
      <div className="row">
        {filteredProjects.map((item, index) => (
          <div
            key={index}
            className="col-md-4 mb-4 d-flex justify-content-center align-items-center"
          >
            <div
              key={item.heading} // Adding a unique key based on project heading
              style={{ backgroundImage: `url(${item.img})` }}
              className="position-relative bg-img"
            >
              <div className="position-relative">
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className=" rounded-pill p-1 position-absolute btn text-primary fw-bold"
                  style={{ top: "-1em", right: "-4em" }}
                >
                  <FontAwesomeIcon icon={faLink} />
                </a>
                <h6 className="mt-2 mb-2 text-white">{item.heading}</h6>
                <p className="text-white">{item.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentWork;
