export const lightTheme = {
    body: '#ffffff',
    text: '#333333',
    textsecondary:"#707070",
    primaryColor: '#007bff',
    secondaryColor: '#6c757d',
  };
  
  export const darkTheme = {
    body: '#1a1a1a',
    text: '#ffffff',
    textsecondary: '#ADD8E6',
    primaryColor: '#007bff',
    secondaryColor: '#6c757d',
  };
  