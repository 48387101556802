// Cursor.js
import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import world from '../img/world.png';
import '../App.css'; 

const Cursor = ({ theme }) => {
  useEffect(() => {
    gsap.set('.ball', { xPercent: -50, yPercent: -50 });
    let targets = gsap.utils.toArray('.ball');
    const onMouseMove = (e) => {
      gsap.to(targets, {
        duration: 0.2,
        x: e.clientX ,
        y: e.clientY -40,
        ease: 'power1.out',
        overwrite: 'auto',
        stagger: 0.02,
      });
    };
    window.addEventListener('mousemove', onMouseMove);
    
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  return (
    <div>
      <div
        className="ball"
        style={{
          width: '7px',
          height: '5px',
          backgroundColor: theme.text,
          cursor: 'none'
        }}
      />
      <div
        className="ball"
        style={{
          width: '20px',
          height: '10px',
          border: '2px solid gray'
        }}
      />
      <div
        className="ball rotate"
        style={{
          width: '15px',
          height: '5px'
        }}
      >
        {/* <img src={world} width={30} height={30} className="ball" alt="World" /> */}
      </div>
    </div>
  );
};

export default Cursor;
