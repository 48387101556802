import React from 'react'
import Slide from './Slide'
import RecentWork from './RecentWork';
import SliderBG from '../img/sliderbg.jpg'

const Services = ({theme}) => {
    const bgimg = {
        backgroundImage: `url(${SliderBG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        maxWidth: '100vw',
        // display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '40vh',
        // padding: '6em 0',
        // backgroundColor:theme.body ,
        position: 'relative',
      };
  return (
    <div className='main-margin' style={{backgroundColor:theme.body , color: theme.text, minHeight:"100vh"}}>
        <div clas style={bgimg} >
            <h2 className='text-white px-3'>Services </h2>
        <Slide theme={theme}/>

        </div>
        <RecentWork theme={theme}/>
    </div>
  )
}

export default Services