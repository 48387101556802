import React from 'react';

const Quote = ({theme, heading, paragraph, quote, img , vector, paragraph2, reverse="flex-row" }) => {
  return (
    <>
      <div className={`ms-2 ms-md-0 row col-md-10 d-flex px-2  align-items-center justify-content-between  rounded ${reverse}`} >
        <div className='col-md-6 px-5 text-white'>
          <h3 className='display-4 '  style={{color: theme.text==="#ffffff"?theme.textsecondary:theme.secondaryColor}}>{heading}</h3>
          <p className='lead mb-4 ' style={{color:theme.secondaryColor, textAlign:"justify"}}>{paragraph}</p>
          <p className='blockquote-footer ' style={{ fontSize: '16px', color: theme.text==="#ffffff"?theme.text:theme.secondaryColor }}>{quote}</p>
          <button className='mt-4 btn-primary btn bg-transparent fw-bold border-dark ' style={{color:theme.text }}>Learn more</button>
          </div>
         <img className='w-25 p-0 col-md-4 mx-auto mt-3'  src={img} alt='vr'/>
      </div>
      
      <img  className={`col-12 p-0 ${theme.body!=="#ffffff"&&"invert"} ${!vector&&'d-none'}`} src= {vector} alt={vector}/>
      <h4 className=' text-center  pr-5 vrbg-h1 ' style={{color:theme.text!=="#ffffff"?theme.primaryColor:theme.secondaryColor}} >{paragraph2}</h4>
    </>
  );
};

export default Quote;
