import React, { useState } from 'react';
import Hero from './Hero';
import hero1bg from '../img/heroimage.webp'
import hero2bg from '../img/heroimage2.jpeg'

const Contact = ({ theme }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [statusMessage, setStatusMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        setStatusMessage('Sending, please wait...');

        const data = {
            name: name,
            email: email,
            message: message
        };

        try {
            const response = await fetch('https://triyotechbackend.onrender.com/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (response.status === 200) {
                setStatusMessage('Message sent successfully');
                alert('Message sent successfully');
                setEmail("");
                setMessage('');
                setName('');
            } else {
                setStatusMessage('Error sending the message, please try again');
                alert('Failed to send message, please try again after some time.');
            }
        } catch (error) {
            setStatusMessage('Error occurred while sending the message. Please try again later.');
            alert('Error occurred while sending the message. Please try again later.');
        }
    };

    return (
        <div className=' pt-5 main-margin' style={{ minHeight: "110vh", background: theme.body==="#ffffff"?"#f0f4f9":"	#36454F", color: theme.text }}>
            <div className="container" style={{ opacity: 0.8409 }}>
                <div className="d-flex align-items-center justify-content-center" style={{ flexDirection: "column" }}>
                    <h2>Contact Us</h2>
                    <p>Connect with us for any inquiries or collaborations. We'd love to hear from you!</p>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form">
                            <form className="contactForm" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="form-group col-sm-6 my-3">
                                        <input type="text" className="form-control" placeholder="Your Name" required value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="form-group col-sm-6 my-3">
                                        <input type="email" className="form-control" placeholder="Your Email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <textarea className="form-control" rows="5" placeholder="Message" required value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                                </div>
                                <div className="d-flex justify-content-center align-items-center p-3">
                                    <button disabled={statusMessage} className="btn btn-primary" type="submit">Send Message</button>
                                    <p id="status-message" style={{ color: 'yellow' }}>{statusMessage}</p>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6 my-3 rounded">
                        <div className="map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14719.464835100023!2d75.875793!3d22.7332137!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd15e6c3aabb%3A0xb6c519e95a280f7e!2sDignityy%20IT%20Solution%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1706814635563!5m2!1sen!2sin" className='mapiframe' height="200" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='google map'></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-md-flex align-items-center justify-content-between px-md-5">
                <Hero
                    theme={theme}
                    url={hero1bg}
                    heading="Collaboration Tools"
                    paragraph="Explore a vast array of software options, including productivity tools, creative software, and more, all with intuitive controls and natural navigation."
                />
                <div className="px-4 d-none d-md-block" />
                <Hero
                    theme={theme}
                    url={hero2bg}
                    heading="Freedom"
                    paragraph="Limitless Possibilities"
                />
            </div>
        </div>
    );
};

export default Contact;
