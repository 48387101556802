import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Event = ({ theme }) => {
  return (
    <div
      className="container-fluid "
      style={{ backgroundColor: theme.body, color: theme.text , height:"80vh" , marginTop:"8em"}}
    >
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-md-6 ">
          <h1 className="text-center">Event</h1>
        </div>
        <div className="col-md-6 d-flex justify-content-end align-items-center">
          <div className="form-group">
            <select className="form-control w-100">
              <option>Upcoming event</option>
              <option>Not any upcomming event avilable</option>
             
              {/* Add more options here */}
            </select>
          </div>
          <div className="input-group border rounded w-50 mx-3">
            <input
              type="text"
              className="form-control border-0"
              placeholder="Search an event..."
            />
            <div className="input-group-append  d-flex justify-content-end align-items-center">
              <span className="input-group-text border-0  d-flex justify-content-end align-items-center">
                <FontAwesomeIcon icon={faSearch} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-4">
  <div className="row justify-content-center align-items-center  d-flex flex-column">
    <div className="col-md-6 d-flex  justify-content-center">
      <img src={require('../img/events.png')} alt="event" className="img-fluid" />
    </div>
    <div className="col-md-6 d-flex flex-column d-flex align-items-center">
      <h3>No events scheduled yet</h3>
      <p>We couldn't find any event scheduled at this moment.</p>
    </div>
  </div>
</div>

    </div>
  );
};

export default Event;
