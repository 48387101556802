import React, { useState } from "react";
import Pricingbg from "../img/pricingbg.jpg";
import { Button, Modal } from "react-bootstrap";
import OrderForm from "./Orders";

const Pricing = ({ theme }) => {
  const webData = [
    {
      website: "e-commerce",
      webPricing: {
        Beginner: {
          Price: "24,000",
          features: [
            "Facilitate online shopping.",
            "Users to browse and purchase products or services directly.",
            "Product catalog setup included.",
            "No dashboard access.",
            "Responsive design for seamless user experience.",
            "Basic product management functionality.",
            "Customizable templates for your storefront.",
            "24/7 customer support for technical assistance.",
            "Include 0% more for user mobile App.",
            "Unlimited dashboards for comprehensive control.",
            "Free SEO and SMO support.",
            "Domain and hosting management included.",
            "Priority access to new features and updates.",
            "Advanced security measures for data protection.",
            "Integration with popular payment gateways.",
            "Automated marketing tools for promotions.",
          ],
          condition:
            "This price includes a one-time setup fee. Additional customization may incur extra charges.",
          buylink: "/contact",
        },
        Professional: {
          Price: "82,000",
          features: [
            "Facilitate online shopping.",
            "Users to browse and purchase products or services directly.",
            "Limited CRM capabilities and support.",
            "Access to a subset of our cutting-edge modules and features.",
            "Up to 1 major customization request per week.",
            "6 months of free support included.",
            "2 dashboards for enhanced control.",
            "Enhanced CRM functionality with lead tracking.",
            "Advanced reporting and analytics tools.",
            "Multi-language support for a global audience.",
            "Access to premium themes for a unique storefront.",
            "30% more for user mobile App.",
            "Unlimited dashboards for comprehensive control.",
            "Free SEO and SMO support.",
            "Domain and hosting management included.",
            "Priority access to new features and updates.",
            "Advanced security measures for data protection.",
            "Integration with popular payment gateways.",
            "Automated marketing tools for promotions.",
          ],
          condition:
            "Instant setup with a satisfaction guarantee. Refund available if not satisfied.",
          buylink: "/contact",
        },
        Advance: {
          Price: "4,10,000",
          features: [
            "Facilitate online shopping.",
            "Users to browse and purchase products or services directly.",
            "Unlimited CRM power and dedicated support.",
            "Full access to all cutting-edge modules and features.",
            "Unlimited customization options for a tailored experience.",
            "6 months of free premium support included.",
            "Unlimited dashboards for comprehensive control.",
            "Priority access to new features and updates.",
            "Advanced security measures for data protection.",
            "Integration with popular payment gateways.",
            "Automated marketing tools for promotions.",
            "Include 0% more for user mobile App.",
            "Free SEO and SMO support.",
            "Domain and hosting management included.",
          ],
          condition:
            "Instant setup with a satisfaction guarantee. Refund available if not satisfied.",
          buylink: "/contact",
        },
      },
    },
    {
      website: "Personal",
      webPricing: {
        Beginner: {
          Price: "16,000",
          features: [
            "Basic personal website setup.",
            "Responsive design for optimal viewing on various devices.",
            "Integration with social media profiles.",
            "Contact form for easy communication.",
            "Unlimited dashboards for comprehensive control.",
            "Free SEO and SMO support.",
            "Domain and hosting management included.",
            "Priority access to new features and updates.",
            "Advanced security measures for data protection.",
            "Integration with popular payment gateways.",
            "Automated marketing tools for promotions.",
            "Include 40% more for user mobile App.",
          ],
          condition:
            "This price includes a one-time setup fee. Additional customization may incur extra charges.",
          buylink: "/contact",
        },
        Professional: {
          Price: "1,44,000",
          features: [
            "Advanced personal website setup.",
            "Customizable templates for a unique look.",
            "Blog section for sharing personal stories.",
            "Integration with popular social media platforms.",
            "Unlimited dashboards for comprehensive control.",
            "Free SEO and SMO support.",
            "Domain and hosting management included.",
            "Priority access to new features and updates.",
            "Advanced security measures for data protection.",
            "Integration with popular payment gateways.",
            "Automated marketing tools for promotions.",
            "30% more for user mobile App.",
          ],
          condition:
            "Instant setup with a satisfaction guarantee. Refund available if not satisfied.",
          buylink: "/contact",
        },
        Advance: {
          Price: "1,64,000",
          features: [
            "Comprehensive personal website with advanced features.",
            "E-commerce functionality for personal online store.",
            "Unlimited customization options for a personalized touch.",
            "Integration with SEO tools for better visibility.",
            "Unlimited dashboards for comprehensive control.",
            "Free SEO and SMO support.",
            "Domain and hosting management included.",
            "Priority access to new features and updates.",
            "Advanced security measures for data protection.",
            "Integration with popular payment gateways.",
            "Automated marketing tools for promotions.",
            "Include 0% more for user mobile App.",
          ],
          condition:
            "Instant setup with a satisfaction guarantee. Refund available if not satisfied.",
          buylink: "/contact",
        },
      },
    },
    {
      website: "Blogs & News Websites",
      webPricing: {
        Beginner: {
          Price: "26,000",
          features: [
            "Basic setup for a blog or news website.",
            "Responsive design for optimal viewing on various devices.",
            "User-friendly content management system (CMS).",
            "Integration with social media sharing for wider audience reach.",
            "Unlimited dashboards for comprehensive control.",
            "Free SEO and SMO support for enhanced visibility.",
            "Domain and hosting management included for hassle-free operation.",
            "Priority access to new features and updates.",
            "Advanced security measures ensuring data protection.",
            "Integration with popular payment gateways for smooth transactions.",
            "Automated marketing tools for promotions.",
            "40% extra charges for mobile App integration.",
          ],
          condition:
            "This price includes a one-time setup fee. Additional customization may incur extra charges.",
          buylink: "/contact",
        },
        Professional: {
          Price: "2,72,000",
          features: [
            "Advanced setup for a blog or news website.",
            "Customizable templates for a unique visual appeal.",
            "Multilingual support catering to a global audience.",
            "SEO optimization for improved search engine visibility.",
            "Integration with analytics tools for performance tracking.",
            "Integration with social media sharing for wider audience reach.",
            "Unlimited dashboards for comprehensive control.",
            "Free SEO and SMO support for enhanced visibility.",
            "Domain and hosting management included for hassle-free operation.",
            "Priority access to new features and updates.",
            "Advanced security measures ensuring data protection.",
            "Integration with popular payment gateways for smooth transactions.",
            "Automated marketing tools for promotions.",
            "30% extra charges for mobile App integration.",
          ],
          condition:
            "Instant setup with a satisfaction guarantee. Refund available if not satisfied.",
          buylink: "/contact",
        },
        Advance: {
          Price: "3,64,000",
          features: [
            "Comprehensive setup for a blog or news website with advanced features.",
            "E-commerce functionality for selling digital content.",
            "Integration with analytics tools for performance tracking.",
            "Social media integration for wider reach.",
            "Unlimited dashboards for comprehensive control.",
            "Free SEO and SMO support for enhanced visibility.",
            "Domain and hosting management included for hassle-free operation.",
            "Priority access to new features and updates.",
            "Advanced security measures ensuring data protection.",
            "Integration with popular payment gateways for smooth transactions.",
            "Automated marketing tools for promotions.",
            "0% extra charges for mobile App integration.",
          ],
          condition:
            "Instant setup with a satisfaction guarantee. Refund available if not satisfied.",
          buylink: "/contact",
        },
      },
    },
    {
      website: "Educational",
      webPricing: {
        Beginner: {
          Price: "42,000",
          features: [
            "Basic setup for an educational website.",
            "Responsive design ensuring optimal viewing across devices.",
            "Course catalog setup included for easy access to courses.",
            "User-friendly student management system for efficient organization.",
            "Unlimited dashboards for comprehensive control.",
            "Free SEO and SMO support for enhanced visibility.",
            "Domain and hosting management included for hassle-free operation.",
            "Priority access to new features and updates.",
            "Advanced security measures ensuring data protection.",
            "Integration with popular payment gateways for smooth transactions.",
            "Automated marketing tools for promotions.",
            "40% extra charges for mobile App integration.",
          ],
          condition:
            "This price includes a one-time setup fee. Additional customization may incur extra charges.",
          buylink: "/contact",
        },
        Professional: {
          Price: "1,64,000",
          features: [
            "Advanced setup for an educational website.",
            "Customizable templates for a unique visual appeal.",
            "Integration with e-learning tools and platforms for enhanced learning experience.",
            "Student progress tracking and reporting for monitoring performance.",
            "Unlimited dashboards for comprehensive control.",
            "Free SEO and SMO support for enhanced visibility.",
            "Domain and hosting management included for hassle-free operation.",
            "Priority access to new features and updates.",
            "Advanced security measures ensuring data protection.",
            "Integration with popular payment gateways for smooth transactions.",
            "Automated marketing tools for promotions.",
            "30% extra charges for mobile App integration.",
          ],
          condition:
            "Instant setup with a satisfaction guarantee. Refund available if not satisfied.",
          buylink: "/contact",
        },
        Advance: {
          Price: "3,64,000",
          features: [
            "Comprehensive setup for an educational website with advanced features.",
            "Virtual classroom functionality for live sessions and interactive learning.",
            "Integration with assessment and grading systems for comprehensive evaluation.",
            "Collaborative tools for students and educators for enhanced interaction.",
            "Unlimited dashboards for comprehensive control.",
            "Free SEO and SMO support for enhanced visibility.",
            "Domain and hosting management included for hassle-free operation.",
            "Priority access to new features and updates.",
            "Advanced security measures ensuring data protection.",
            "Integration with popular payment gateways for smooth transactions.",
            "Automated marketing tools for promotions.",
            "0% extra charges for mobile App integration.",
          ],
          condition:
            "Instant setup with a satisfaction guarantee. Refund available if not satisfied.",
          buylink: "/contact",
        },
      },
    },
    {
      website: "Social Media",
      webPricing: {
        Beginner: {
          Price: "55,000",
          features: [
            "Basic setup for a social media platform.",
            "Responsive design ensuring optimal viewing across devices.",
            "User-friendly interface for seamless interaction.",
            "Integration with popular social media networks for wider reach.",
            "Unlimited dashboards for comprehensive control.",
            "Free SEO and SMO support for enhanced visibility.",
            "Domain and hosting management included for hassle-free operation.",
            "Priority access to new features and updates.",
            "Advanced security measures ensuring user data protection.",
            "Integration with popular payment gateways for smooth transactions.",
            "Automated marketing tools for promotions.",
            "40% extra charges for mobile App integration.",
          ],
          condition:
            "This price includes a one-time setup fee. Additional customization may incur extra charges.",
          buylink: "/contact",
        },
        Professional: {
          Price: "4,56,000",
          features: [
            "Advanced setup for a social media platform.",
            "Customizable templates for a unique visual appeal.",
            "Advanced analytics for performance tracking.",
            "Integration with advertising and monetization options for revenue generation.",
            "Unlimited dashboards for comprehensive control.",
            "Free SEO and SMO support for enhanced visibility.",
            "Domain and hosting management included for hassle-free operation.",
            "Priority access to new features and updates.",
            "Advanced security measures ensuring user data protection.",
            "Integration with popular payment gateways for smooth transactions.",
            "Automated marketing tools for promotions.",
            "30% extra charges for mobile App integration.",
          ],
          condition:
            "Instant setup with a satisfaction guarantee. Refund available if not satisfied.",
          buylink: "/contact",
        },
        Advance: {
          Price: "5,64,000",
          features: [
            "Comprehensive setup for a social media platform with advanced features.",
            "API access for third-party integrations for enhanced functionality.",
            "Enhanced security measures for user data protection.",
            "Social media marketing tools for promotion and engagement.",
            "Unlimited dashboards for comprehensive control.",
            "Free SEO and SMO support for enhanced visibility.",
            "Domain and hosting management included for hassle-free operation.",
            "Priority access to new features and updates.",
            "Advanced security measures ensuring user data protection.",
            "Integration with popular payment gateways for smooth transactions.",
            "Automated marketing tools for promotions.",
            "0% extra charges for mobile App integration.",
          ],
          condition:
            "Instant setup with a satisfaction guarantee. Refund available if not satisfied.",
          buylink: "/contact",
        },
      },
    },
  ];
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalShow = () => {
    setShowModal(true);
  };

  return (
    <>
      <div
        className="my-5 py-5 text-white"
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
          backgroundColor: theme.body,
          fontFamily: "robotics, serif",
        }}
      >
        <div className="container text-white " style={{ opacity: 0.8409 }}>
          <div
            className="d-flex align-items-center justify-content-center text-white"
            style={{
              flexDirection: "column",
              background: ` url(${Pricingbg})`,
            }}
          >
            <h2
              style={{ color: theme.text === "#ffffff" ? "text-bg" : "white" }}
            >
              Pricing
            </h2>
          </div>
          {webData.map((item, index) => (
            <div
              key={index}
              className={`my-4 ${
                index !== 0 && "border-top border-dark border-3"
              }`}
            >
              <h3
                className="text-center my-3"
                style={{ color: theme.text, fontFamily: "jejuHallasan" }}
              >
                For {item.website}
              </h3>
              <div className="row justify-content-between align-items-start align-content-center">
                {Object.keys(item.webPricing).map((key) => {
                  const pricingItem = item.webPricing[key];
                  return (
                    <div
                      key={key}
                      className={`ms-3 ms-md-0 mt-md-0 mt-2 col-md-4 col-11 align-content-center py-3 rounded border border-dark text-dark row align-items-center p-0 justify-content-center text-center ${
                        key === "Beginner" && "bg-secondary"
                      } ${key === "Professional" && "bg-primary"} ${
                        key === "Advance" && "bg-brand"
                      }`}
                    >
                      <header className="border-0 fs-3 text-white">
                        {key}
                      </header>
                      <h4 className="p-4 fw-bold text-white">
                        ₹ {pricingItem.Price}
                      </h4>
                      <div className="row align-items-center justify-content-center text-center bg-white m-0 py-3">
                        {pricingItem.features.map((feature, i) => (
                          <p
                            key={i}
                            className={
                              i !== pricingItem.features.length - 1
                                ? "border-bottom"
                                : ""
                            }
                          >
                            {feature}
                          </p>
                        ))}
                      </div>
                      <div className="row align-items-center justify-content-center text-center py-3 text-white">
                        <p>{pricingItem.condition}</p>
                        <Button
                          onClick={handleModalShow}
                          className={`bg-brand btn w-50 ${
                            key !== "Advance" ? "border-0" : ""
                          }`}
                        >
                          Book Now
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
        <Modal show={showModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <OrderForm webData={webData} />
          </Modal.Body>
        </Modal>
      </div>
      <div className=" mt-5 pt-5 d-md-none" />
    </>
  );
};

export default Pricing;
