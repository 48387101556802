// App.js
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./Components/Header";
import Hero from "./Components/Hero";
import RecentWork from "./Components/RecentWork";
import Quote from "./Components/Quote";
import vr from "./img/vr.png";
import quote2 from "./img/quote2.png";
import vector from "./img/Vector.png";
import heroheader from "./img/hero0.jpg";
import { lightTheme, darkTheme } from "./themes";
import { Helmet } from 'react-helmet';
import Footer from "./Components/Footer";
import Cursor from "./Components/cursor";
import Event from "./Components/Event";
import PageNotFound from "./Components/PageNotFound";
import Services from "./Components/Services";
import Contact from "./Components/Contact";
import Pricing from "./Components/Pricing";

// Enhanced SEO Component for better SEO and social media integration
const SEO = ({ title, description, url, image }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <link rel="icon" href="https://media.licdn.com/dms/image/D4D0BAQGrt07orzYI6w/company-logo_100_100/0/1715183545896/triyotechindia_logo?e=1723075200&v=beta&t=9Qlzbk9H0PgMAnacPmORtCJc6Qs0YzoF1QwBV3GhVKU" />
    <link rel="apple-touch-icon" href="https://media.licdn.com/dms/image/D4D0BAQGrt07orzYI6w/company-logo_100_100/0/1715183545896/triyotechindia_logo?e=1723075200&v=beta&t=9Qlzbk9H0PgMAnacPmORtCJc6Qs0YzoF1QwBV3GhVKU" />
    <link rel="manifest" href="https://media.licdn.com/dms/image/D4D0BAQGrt07orzYI6w/company-logo_100_100/0/1715183545896/triyotechindia_logo?e=1723075200&v=beta&t=9Qlzbk9H0PgMAnacPmORtCJc6Qs0YzoF1QwBV3GhVKU" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:image" content={image} />
    <meta property="twitter:card" content={image} />
    <meta property="twitter:url" content={url} />
    <meta property="twitter:title" content={title} />
    <meta property="twitter:description" content={description} />
    <meta property="twitter:image" content={image} />
    <meta property="linkedin:card" content="summary_large_image" />
    <meta property="linkedin:url" content={url} />
    <meta property="instagram:card" content="summary_large_image" />
    <meta property="instagram:url" content={url} />
    <meta property="mastodon:card" content="summary_large_image" />
    <meta property="mastodon:url" content={url} />
    <link rel="canonical" href={url} />
    <link rel="alternate" href="https://github.com/sachinpandeygurh" hreflang="en" />
    <link rel="alternate" href="https://maps.app.goo.gl/L5qhi9Ym3F5T4ghA7" hreflang="en" />
    <link rel="alternate" href="https://in.linkedin.com/company/triyotechindia" hreflang="en" />
    <link rel="alternate" href="https://www.facebook.com/triyotech" hreflang="en" />
    <link rel="alternate" href="https://www.instagram.com/triyotechindian/" hreflang="en" />
    <link rel="alternate" href="https://twitter.com/triyotech" hreflang="en" />
    <link rel="alternate" href="https://mastodon.social/@triyotech" hreflang="en" />
  </Helmet>
);

function App() {
  const [theme, setTheme] = useState(lightTheme);
  const [menubarVisible, setMenubarVisible] = useState(false);

  const toggleTheme = () => {
    setTheme(theme === lightTheme ? darkTheme : lightTheme);
  };

  const toggleMenubar = () => {
    setMenubarVisible(!menubarVisible);
  };

  return (
    <Router>
      <div style={{ background: theme.body, color: theme.secondaryColor }}>
        <Header
          theme={theme}
          toggleTheme={toggleTheme}
          toggleMenubar={toggleMenubar}
          menubarVisible={menubarVisible}
        />
        <Routes>
          <Route path="*" element={<PageNotFound theme={theme} />} />
          <Route
            path="/"
            element={
              <>
                <SEO 
                  title="Triyotech India - Revolutionizing Online Business" 
                  description="Triyotech India provides innovative software solutions to streamline processes, increase productivity, and drive growth for businesses of all sizes."
                  url="https://triyotech.com"
                  image="https://media.licdn.com/dms/image/D4D0BAQGrt07orzYI6w/company-logo_100_100/0/1715183545896/triyotechindia_logo?e=1723075200&v=beta&t=9Qlzbk9H0PgMAnacPmORtCJc6Qs0YzoF1QwBV3GhVKU"
                />
                <Cursor theme={theme} />
                <Hero
                  theme={theme}
                  url={heroheader}
                  heading="Revolutionizing Online Business with Triyotech India"
                />
                <div
                  className="position-relative pt-5 h-75 d-flex row text-dark p-0"
                  style={{
                    background: theme.body !== "#ffffff" ? "#23395b" : theme.body,
                    color: theme.secondaryColor,
                  }}
                >
                  <Quote
                    theme={theme}
                    heading="Effortless Integration"
                    paragraph="Triyotech India provides innovative software solutions that cater to the needs of businesses of all sizes. Our products are designed to streamline processes, increase productivity, and drive growth."
                    quote="Start with the customer and work backward. - Steve Jobs"
                    img={vr}
                    vector={vector}
                    paragraph2="Discover a New World of Possibilities"
                  />
                </div>
                <div
                  className="position-relative pt-5 h-75 d-flex row text-dark p-0"
                  style={{
                    background: theme.body,
                    color: theme.secondaryColor,
                  }}
                >
                  <Quote
                    theme={theme}
                    heading="Step into the Future"
                    paragraph="Triyotech India provides innovative software solutions that cater to the needs of businesses of all sizes. Our products are designed to streamline processes, increase productivity, and drive growth."
                    quote="Start with the customer and work backward. - Steve Jobs"
                    img={quote2}
                    reverse="flex-row-reverse"
                  />
                </div>
                <div
                  className="d-md-flex align-items-center justify-content-between px-md-5"
                  style={{
                    background: theme.body,
                    color: theme.secondaryColor,
                  }}
                />
                <Contact theme={theme} />
              </>
            }
          />
          <Route
            path="/events"
            element={
              <>
                <SEO 
                  title="Events - Triyotech India" 
                  description="Stay updated with the latest events and happenings at Triyotech India. Join us to learn more about our innovative solutions."
                  url="https://triyotech.com/events"
                  image="https://media.licdn.com/dms/image/D4D0BAQGrt07orzYI6w/company-logo_100_100/0/1715183545896/triyotechindia_logo?e=1723075200&v=beta&t=9Qlzbk9H0PgMAnacPmORtCJc6Qs0YzoF1QwBV3GhVKU"
                />
                <Event theme={theme} />
              </>
            }
          />
          <Route
            path="/services"
            element={
              <>
                <SEO 
                  title="Services - Triyotech India" 
                  description="Explore the wide range of services offered by Triyotech India, designed to meet all your business needs."
                  url="https://triyotech.com/services"
                  image="https://media.licdn.com/dms/image/D4D0BAQGrt07orzYI6w/company-logo_100_100/0/1715183545896/triyotechindia_logo?e=1723075200&v=beta&t=9Qlzbk9H0PgMAnacPmORtCJc6Qs0YzoF1QwBV3GhVKU"
                />
                <Services theme={theme} />
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <SEO 
                  title="Contact - Triyotech India" 
                  description="Get in touch with Triyotech India. We are here to assist you with any queries or support you may need."
                  url="https://triyotech.com/contact"
                  image="https://media.licdn.com/dms/image/D4D0BAQGrt07orzYI6w/company-logo_100_100/0/1715183545896/triyotechindia_logo?e=1723075200&v=beta&t=9Qlzbk9H0PgMAnacPmORtCJc6Qs0YzoF1QwBV3GhVKU"
                />
                <Contact theme={theme} />
              </>
            }
          />
          <Route
            path="/pricing"
            element={
              <>
                <SEO 
                  title="Pricing - Triyotech India" 
                  description="Discover our competitive pricing plans for various services and products at Triyotech India."
                  url="https://triyotech.com/pricing"
                  image="https://media.licdn.com/dms/image/D4D0BAQGrt07orzYI6w/company-logo_100_100/0/1715183545896/triyotechindia_logo?e=1723075200&v=beta&t=9Qlzbk9H0PgMAnacPmORtCJc6Qs0YzoF1QwBV3GhVKU"
                />
                <Pricing theme={theme} />
              </>
            }
          />
        </Routes>
        <Footer theme={theme} />
      </div>
    </Router>
  );
}

export default App;
