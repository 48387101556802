import React, { useEffect, useState } from "react";
import { Button, Form, Dropdown } from "react-bootstrap";
import ReactDOM from 'react-dom';
import html2pdf from 'html2pdf.js';
import { useNavigate } from 'react-router-dom';
const OrderForm = ({ webData }) => {
  const  navigate = useNavigate()
    const [websiteType, setWebsiteType] = useState("");
    const [pricingTier, setPricingTier] = useState("");
    const [price, setPrice] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [statusMessage, setStatusMessage] = useState("");

  
    useEffect(() => {
      setMessage(`User filled order form. Selected Website type is ${websiteType}, selected pricing tier is ${pricingTier}, and price is ₹${price}`);
    }, [websiteType, pricingTier, price]);

  const handleWebsiteTypeChange = (eventKey, event) => {
    setWebsiteType(event.target.text);
    setPricingTier("");
  };

  const handlePricingTierChange = (eventKey, event) => {
    setPricingTier(event.target.text);
    setPrice(webData.find((web) => web.website === websiteType)?.webPricing[event.target.text].Price);
  };

  const handleBuy = async (event) => {
    event.preventDefault();

    setStatusMessage('Processing, please wait...');

    const data = {
      name: name,
      email: email,
      message: message
    };

    try {
      const response = await fetch('https://triyotechbackend.onrender.com/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (response.status === 200) {
        setStatusMessage('Quotation get successfully');
        alert('Quotation get successfully');
        printToPdf()
      } else {
        setStatusMessage('Error sending the message, please try again');
        alert('Failed to send message, please try again after some time.');
      }
    } catch (error) {
      setStatusMessage('Error occurred while sending the message. Please try again later.');
      alert('Error occurred while sending the message. Please try again later.');
    }
setTimeout(() => {
    
    setPrice('')
    setPricingTier('')
    setWebsiteType('')
    setEmail("");
    setMessage('');
    setName('');
    setStatusMessage('')
}, 3400);

  };
  
  const printToPdf = () => {
    const element = document.getElementById('client-quotation');
    const date = new Date().toLocaleString();
    
    html2pdf()
      .from(element)
      .save(`triyotech_${websiteType}_quotation_${date}.pdf`);

  };

  
  
  const ClientQuotations = (
    <div id="client-quotation" className="mt-4 p-4 border rounded">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="mb-4">Quotation</h4>
        <h6 className="mb-4">{new Date().toLocaleString()}</h6>
      </div>
      <p>
        Dear Sir/Madam,<br />
        <br />
        We are pleased to provide you with a quotation for your project. Please find the details below:<br />
        <br />
        <strong>Client Information:</strong><br />
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>Name:</td>
              <td>{name}</td>
            </tr>
            <tr>
              <td>Email:</td>
              <td>{email}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <strong>Project Details:</strong><br />
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>Website Type:</td>
              <td>{websiteType}</td>
            </tr>
            <tr>
              <td>Pricing Tier:</td>
              <td>{pricingTier}</td>
            </tr>
            <tr>
              <td>Price:</td>
              <td>₹{price}</td>
            </tr>
          </tbody>
        </table>
        <br />
        For further inquiries or to proceed with this quotation, please feel free to contact us at +91 8319697083 or visit our website at <a href="https://triyotech.com">triyotech.com</a>.<br />
        <br />
        Thank you for considering Triyotech India. We look forward to the opportunity to work with you.<br />
        <br />
        <strong>Terms & Conditions Apply</strong>
      </p>
      <button className="btn btn-primary" onClick={printToPdf}>Download</button>
    </div>
  );
    
  ReactDOM.render(ClientQuotations, document.getElementById('root'));
  
  return (
    <Form className="p-md-4 p-1 border rounded text-dark row align-items-center justify-content-center">
      <Form.Group controlId="websiteTypeDropdown" className="d-flex justify-content-between align-items-center my-3">
        <Form.Label className="mb-0">Website Type</Form.Label>
        <Dropdown onSelect={handleWebsiteTypeChange}>
          <Dropdown.Toggle variant="outline-primary" id="dropdown-websiteType">
            {websiteType || "Select Website Type"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {webData.map((web) => (
              <Dropdown.Item key={web.website}>{web.website}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Form.Group>

      {websiteType && (
        <Form.Group controlId="pricingTierDropdown" className="d-flex justify-content-between align-items-center my-3">
          <Form.Label className="mb-0">Pricing Tier</Form.Label>
          <Dropdown onSelect={handlePricingTierChange}>
            <Dropdown.Toggle variant="outline-primary" id="dropdown-pricingTier">
              {pricingTier || "Select Pricing Tier"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.keys(webData.find((web) => web.website === websiteType)?.webPricing || {}).map((tier) => (
                <Dropdown.Item key={tier}>{tier}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>
      )}

      {price && (
        <Form.Group controlId="priceField" className="d-flex justify-content-between align-items-center my-3">
          <Form.Label className="mb-0">Price</Form.Label>
          <Form.Control className="w-25 px-1 text-end border-0 fw-bold" type="text" value={"₹ " + price} readOnly />
        </Form.Group>
      )}

      {price && (
        <>
          <div className="row">
            <div className="form-group col-sm-6 my-3">
              <input type="text" className="form-control" placeholder="Your Name" required value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="form-group col-sm-6 my-3">
              <input type="email" className="form-control" placeholder="Your Email" required value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center p-3">
            <p id="status-message" style={{ color:statusMessage==='Quotation get successfully'?"green" :'red' }}>{statusMessage}</p>
          </div>
          <Button onClick={handleBuy} className={`w-md-25 w-50 rounded bg-brand border-0 ${statusMessage==='Quotation get successfully'&& 'd-none'}`}  variant="primary" type="submit">
          Download Quotation
          </Button>
          {statusMessage==='Quotation get successfully'&&ClientQuotations}
        </>
      )}
    </Form>
  );
};

export default OrderForm;
